.App {
  min-width: 300px;
}

.Privacy-section {
  /* background-color: #282c340d; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 1vmin);
  background-color: #282c34;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.App .Privacy-section h2 {
  margin: 20px;
}

.App .Privacy-section h4 {
  margin: 10px;
  text-align: left;
  width: 100%;
}

.App .Privacy-section p {
  /* padding-left: 20px;
  padding-right: 20px; */
  text-align: justify;
}

.App .Privacy-section p a {
  color: rgb(43, 165, 165);
  text-decoration: none;
  font-weight: 800;
  /* transition: ease 0.3s all; */
  position: relative;
}