/* body {
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  
} */

button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.App {
  min-width: 300px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-section {
  background-color: #282c340d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-section:nth-of-type(even) {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* ------------------------ HEADER ---------------------- */

.home-container {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 800px;
  width: 75%;
  padding-top: 75px;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.home-container h1 {
  margin: 10px 0;
  animation: 0.6s ease-out 0s 1 slideInFromBottom;
}

.home-container h3 {
  margin: 0;
  color: rgba(255, 255, 255, 0.584);
  animation: 0.6s ease-out 0s 1 slideInFromBottom;
}

.home-container p {
  margin-top: 50px;
  color: rgba(13, 174, 177, 0.736);
  animation: 0.8s ease-out 0s 1 slideInFromBottom;
  /* animation-delay: 0.2s; */
}

/* ------------------------ NAVBAR ---------------------- */

#navbar {
  transition: ease 0.5s all;
  top: 0;
  display: flex;
  flex-direction: row;
}

.nav {
  position: fixed;
  width: 100%;
  z-index: 1;
  background-color: rgb(27, 30, 38);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  transition: ease 0.3s all;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgba(255, 255, 255, 0.584);
  text-decoration: none;
  transition: ease 0.3s all;
  font-weight: 400;
  border: 2px solid rgba(13, 174, 177, 0);
}

.nav-main-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgba(255, 255, 255);
  font-size: 1.3em;
  text-decoration: none;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  z-index: 2;
}

.nav-link.resume {
  border: 2px solid rgba(13, 174, 177, 0.306);
}

.nav-link:hover,
.nav-main-link:hover {
  text-decoration: none;
  color: rgb(255, 255, 255);
  transition: ease 0.3s all;
}

.nav-link.active {
  color: rgb(255, 255, 255);
  background-color: rgba(112, 116, 125, 0.28);
}

.nav .navbar-wrapper ul,
.nav ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-decoration: none;
  padding-left: 0;
}

.nav .navbar-wrapper ul li,
.nav ul li {
  list-style-type: none;
  text-decoration: none;
}

.navbar-wrapper {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.hamburger {
  align-items: center;
  padding-top: 1.3em;
}

.nav.open .navbar-wrapper {
  transform: translateY(0) scaleY(1);
  transition: ease 0.3s all;
  opacity: 1;
  visibility: visible;
  display: flex;
  justify-content: right;
}

.nav.open .nav-link {
  visibility: visible;
}

.nav.open .navbar-wrapper ul {
  justify-content: space-between;
  padding: 0 10px;
}

@media (max-width: 700px) {
  .nav-link {
    visibility: hidden;
  }

  .navbar-wrapper {
    position: absolute;
    z-index: -3;
    left: 0;
    top: 50px;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    background: linear-gradient(180deg, rgb(27, 30, 38) 30%, #282c34ed 100%);
    transform: translateY(-20px) scaleY(0);
    transition: ease 0.4s all;
  }

  .nav.open .navbar-wrapper {
    width: 100%;
    /* justify-content: space-between; */
  }

  .navbar-wrapper ul li {
    justify-content: space-between;
  }

  .navbar-wrapper ul {
    margin-left: auto;
    text-align: center;
    padding: 0 10px;
  }
}

@media (max-width: 575px) {
  .nav .navbar-wrapper ul {
    flex-direction: column;
    padding: 0 10px;
    /* justify-content: space-between; */
    /* text-align: right; */
  }

  .navbar-wrapper {
    transform: translateY(-100px) scaleY(0);
  }
}

/* ------------------------ HAMBURGER ---------------------- */

.burger {
  position: absolute;
  top: 1.1em;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  transform: translateX(0);
  transition: ease 0.3s all;
}

/* --- CLOSED --- */

.burger div {
  width: 2rem;
  height: 0.25rem;
  background: white;
  border-radius: 10px;
  transition: all 0.2s linear;
  position: relative;
  transform-origin: 1px;
}

.burger div:first-child {
  transform: rotate(0);
}

.burger div:nth-child(2) {
  opacity: 1;
}

.burger div:nth-child(3) {
  transform: rotate(0);
}

@media (min-width: 700px) {
  .burger {
    display: none;
    transform: translateX(0);
  }
}

/* --- OPENED --- */

@media (max-width: 575px) {
  .burger.open {
    transform: translateX(-18px);
    transition: ease 0.3s all;
  }
}

.burger.open div {
  background: grey;
}

.burger.open div:first-child {
  transform: translateX(3px) rotateZ(45deg);
}

.burger.open div:nth-child(2) {
  opacity: 0;
}

.burger.open div:nth-child(3) {
  transform: translateX(3px) rotateZ(-45deg);
}

/* ------------------------ PROJECT ---------------------- */

@media (max-height: 820px) {
  #projects {
    padding: 50px 0;
  }
}

@media (max-width: 1000px) {
  #projects {
    justify-content: start;
  }
}

.project-sidebar-container {
  flex-direction: row;
  display: flex;
  margin: 50px;
}

@media (max-width: 800px) {
  .project-card {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
}

/* ------------ PROJECT-NAV ---------- */

.projects-nav {
  min-width: 200px;
}

.projects-nav ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  padding-left: 0;
}

.projects-nav ul li {
  list-style-type: none;
  text-decoration: none;
}

@media (max-width: 800px) {
  .project-sidebar-container {
    flex-direction: column;
    margin: 0 40px;
    width: 95%;
  }

  .projects-nav {
    overflow-x: auto;
    white-space: nowrap;
  }

  .projects-nav ul {
    flex-direction: row;
  }
}

@media (max-width: 600px) {
  .projects-nav {
    border-bottom: 3px solid rgba(13, 174, 177, 0.776);
  }

  .projects-nav ul li .project-tab {
    margin: 0;
  }
}

.project-tab {
  text-transform: capitalize;
  margin: 15px auto;
  padding: 10px;
  border: 2px solid rgba(13, 174, 177, 0);
  cursor: pointer;
  font-size: 16px;
  background: rgba(0, 0, 0, 0);
  color: rgba(255, 255, 255, 0.584);
  position: relative;
  transition: ease 0.3s all;
  float: right;
}

.project-tab.active {
  text-transform: capitalize;
  margin: 15px auto;
  padding: 10px;
  border: 2px solid rgba(13, 174, 177, 0.406);
  cursor: pointer;
  font-size: 16px;
  background: rgba(0, 0, 0, 0);
  color: rgb(255, 255, 255);
  position: relative;
  transition: ease 0.3s all;
  background-color: rgba(112, 116, 125, 0.28);
}

.project-tab:hover,
.project-tab:focus {
  text-decoration: none;
  color: rgb(255, 255, 255);
  transition: ease 0.3s all;
  border: 2px solid rgba(13, 174, 177, 0.406);
}

button:focus {
  outline: 0;
}

/* ------------ PROJECT-CARD ---------- */

.project-card {
  max-width: 750px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  border: 1px solid #dad7d7;
  border-radius: 3px;
}

@media (max-width: 800px) {
  .project-card {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
}

@media (max-width: 600px) {
  .project-card {
    border: 0;
  }
}

/* ------------ PROJECT-TOP-BAR ---------- */

.project-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  padding: 10px 12px;
  border-bottom: 3px solid rgba(13, 174, 177, 0.776);
  font-size: 24px;
}

.project-title {
  font-size: 1.2em;
}

.project-date {
  font-size: 1em;
  line-height: 1.5em;
}

.project-title a {
  color: rgb(255, 255, 255);
  text-decoration: none;
  transition: ease 0.3s all;
}

.project-title a:hover {
  color: rgba(29, 182, 184, 0.776);
  transition: ease 0.3s all;
}

@media (max-width: 600px) {
  .project-top-bar {
    flex-direction: column;
    border-bottom: 0;
  }

  .project-title {
    font-size: 1em;
    text-align: center;
  }

  .project-date {
    font-size: 0.8em;
    line-height: 1em;
    text-align: center;
    color: grey;
  }
}

.project-img {
  padding-top: 25px;
  margin-left: auto;
  margin-right: auto;
}

.project-img img {
  margin-right: 10px;
  transition: ease 0.3s all;
}

.project-img img:hover {
  transform: translate(-4px, -4px);
  box-shadow: 4px 4px 5px rgba(0, 103, 103, 1);
}

.project-body {
  height: 100%;
  display: flex;
  flex-direction: row;
}

@media (max-width: 1000px) {
  .project-body {
    flex-direction: column-reverse;
  }

  .project-body.no-picture {
    flex-direction: column;
  }
}

.project-bullets {
  padding-bottom: 15px;
}

.project-bullets ul li {
  font-size: 20px;
  text-align: justify;
  padding-right: 20px;
  padding-bottom: 15px;
}

@media (max-width: 500px) {
  .project-bullets ul li {
    font-size: 18px;
  }
}

/* ------------ PROJECT-BOTTOM-BAR ---------- */

.project-bottom-bar {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  color: rgba(29, 182, 184, 0.776);
}

.project-code {
  line-height: 2em;
  font-size: 20px;
}

.project-bottom-bar a {
  font-size: 24px;
}

@media (max-width: 500px) {
  .project-bottom-bar {
    flex-direction: column;
  }

  .project-code {
    line-height: 1.5em;
    font-size: 16px;
    text-align: center;
  }

  .project-bottom-bar a {
    text-align: center;
    padding-top: 10px;
  }
}

/* ------------------------ EXPERIENCE ---------------------- */

#experience {
  padding: 50px 0;
  overflow-x: auto;
}

.paper {
  padding: 6px 16px;
  margin-bottom: 20px;
  background-color: rgba(0, 103, 103, 1);
  transition: ease 0.3s all !important;
}

.paper:hover {
  background-color: rgba(0, 103, 103, 0.1);
  transform: translateY(-4px);
  transition: ease 0.3s all;
}

.paper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -1px;
  left: 0;
  background-color: rgba(0, 103, 103, 1);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.paper:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.paper a {
  text-decoration: none;
  color: black;
}

.timeline {
  width: 65%;
  min-width: 750px;
}

.timeline-item-title {
  font-weight: bold !important;
}

.timeline-dot {
  background-color: rgb(0, 103, 103);
  border: transparent;
  border-color: transparent;
}

.timeline-dot-outline {
  border-color: rgb(0, 103, 103);
}

@media (max-width: 800px) {
  .timeline {
    width: 95%;
    min-width: 0;
  }

  .MuiTimelineOppositeContent-root {
    /* DATE */
    max-width: 35%;
  }
}

@media (max-width: 600px) {
  .timeline {
    width: 95%;
    min-width: 0;
  }

  .MuiTimelineOppositeContent-root {
    /* DATE */
    max-width: 25%;
  }
}

@media (max-width: 400px) {
  .timeline {
    width: 95%;
    min-width: 0;
  }

  .MuiPaper-root {
    padding: 4px 12px;
  }

  .MuiTimelineOppositeContent-root {
    padding: 4px 12px !important;
  }

  .MuiTimeline-root {
    padding: 0 !important;
  }

  .MuiTimelineItem-content {
    padding: 4px 12px !important;
  }

  .MuiTimelineOppositeContent-root {
    /* DATE */
    max-width: 20%;
  }
}

/* ------------------------ ABOUT ME ---------------------- */

@media (max-height: 900px) {
  #about-me {
    padding: 50px 0;
  }
}

#about-me .profile-pic {
  margin-right: auto;
  margin-left: auto;
}

#about-me a img {
  margin-bottom: 25px;
  display: flex;
  transition: ease 0.3s all;
}

#about-me a img:hover {
  transform: translate(-4px, -4px);
  box-shadow: 5px 5px 5px rgba(0, 103, 103, 1);
}

#about-me p {
  font-size: large;
  max-width: 850px;
  text-align: justify;
  padding: 0 50px;
  line-height: 1.4em;
}

#about-me p a {
  color: rgba(0, 103, 103, 1);
  text-decoration: none;
  font-weight: 800;
  transition: ease 0.3s all;
  position: relative;
}

#about-me p a:hover {
  color: rgba(0, 103, 103, 0.6);
  transition: ease 0.3s all;
}

#about-me p a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -1px;
  left: 0;
  background-color: rgba(0, 103, 103, 1);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

#about-me p a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

@media (max-width: 500px) {
  #about-me {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  #about-me p {
    padding: 0 25px;
  }
}

/* ------------------------ ANIMATIONS ---------------------- */

.slide-up {
  animation: slide-up 0.9s ease forwards;
}

.item-to-slide-up {
  opacity: 0;
}

@keyframes slide-up {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-left {
  animation: slide-left 0.9s ease forwards;
}

.item-to-slide-left {
  opacity: 0;
}

@keyframes slide-left {
  0% {
    transform: translateX(150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* ------------------------ OTHER ---------------------- */

.box {
  border: 1px solid red;
}

.Icon-link {
  color: white;
  padding: 5px;
  transition: ease 0.3s all;
}

.Icon-link:hover {
  color: rgba(0, 162, 162, 0.8);
  transition: ease 0.3s all;
}

#Link {
  color: white;
}
